<script setup lang="ts">
import api from "@/api";
import { useDefaultStore } from "@/store";
import type { TFilePickerChange } from "src/components/shared/fileUpload/FilePicker.vue";
import { $t } from "@/i18n";
import { useToastStore } from "@/store/toast";
import { useRoute, useRouter } from "vue-router";
import type { IArticleItem } from "@/components/landingPage/NewsSection.vue";
import { useSessionStore } from "@/store/session";

definePage({
	name: "EditArticle",
});

const route = useRoute();
// const route = useRoute();
const store = useDefaultStore();
const storeSession = useSessionStore();
const toast = useToastStore();
// const errorMsgBool = ref(false);
// const errorMsgText = ref("");
// const errorMsgTimeout = ref<ReturnType<typeof setTimeout> | null>(null);
const coverImagePreviewUrl = ref("");
const slug = ref("");
const articleItem = ref<IArticleItem | null>(null);
let timeout: ReturnType<typeof setTimeout> | null = null;
const router = useRouter();

useHead({
	title: `${$t("app.admin.editArticleTitle")} | ${store.appName}`,
	meta: [
		{
			name: "title",
			content: `${$t("app.admin.editArticleTitle")} | ${store.appName}`,
		},
		{
			name: "description",
			content: `${$t("app.metaInfo.description")}`,
		},
		{
			name: "keywords",
			content: `${$t("app.metaInfo.keywords")}`,
		},
		{ name: "robots", content: store.metaData.robotsNoIndex },
		// { name: "author", content: store.META_DATA.NAME_AUTHOR },
		{
			property: "og:title",
			content: `${$t("app.admin.editArticleTitle")} | ${store.appName}`,
		},
		{
			property: "og:description",
			content: `${$t("app.metaInfo.description")}`,
		},
		// { property: "og:image", content: store.META_DATA.PROPERTY_IMAGE },
		{ property: "og:site_name", content: store.appName },
		{
			property: "og:url",
			content: store.baseUrl + route.path,
		},
		{ property: "og:type", content: "website" },
	],
});

const form = reactive({
	art_id: -1,
	title: "",
	date: "",
	image: null as File | null,
	// image_alt: null as string | null,
	locale: "empty",
	status: "draft",
	content: "" as string | "",
	livestream: null as string | null,
	description: "",
	sid: storeSession.userToken,
});

const setFormValues = (articleData: IArticleItem) => {
	form.art_id = articleData.art_id;
	form.date = articleData.art_utc_date;
	form.title = articleData.art_title;
	form.image = null;
	// form.image_alt = articleData.img_alt;
	form.locale = articleData.art_locale;
	form.status = articleData.art_status;
	form.content = articleData.art_content;
	form.livestream = articleData.art_livestream;
	form.description = articleData.art_description;
};

const isValidLoginForm = computed(() => {
	const hasFields = !!(
		form.title &&
		form.date &&
		form.locale &&
		form.status &&
		form.content &&
		// form.image_alt &&
		form.description
	);
	if (form.locale === "empty") return false;
	if (hasFields) return true;
	return false;
});

// Get article

async function getIdFromPath() {
	if (Array.isArray(route.params.id)) {
		const id = parseInt(route.params.id[0], 10);
		if (!isNaN(id) && id > 0) {
			await getNews(id);
			// console.log(id);
		}
	} else {
		const id = parseInt(route.params.id, 10);
		if (!isNaN(id) && id > 0) {
			await getNews(id);
			// console.log(id);
		}
	}
}

async function getNews(id: number) {
	const params = {
		art_id: id,
		published: false,
		sid: storeSession.userToken,
	};
	try {
		const res = await api.articlesGet(params);
		articleItem.value = res.data.data[0];
		// console.log(articleItem.value);

		if (articleItem.value) {
			// handle images

			coverImagePreviewUrl.value =
				articleItem.value.img_image ?? store.noImagePath;

			setFormValues(articleItem.value);
		} else {
			timeout = setTimeout(() => {
				void router.replace("/");
			}, 5000);
		}

		return true;
	} catch (err: any) {
		console.warn(err.message);
		return false;
	}
}

// File change

function handleCoverFileChange(event: TFilePickerChange) {
	form.image = event.files ? event.files[0] : null;

	if (event.files && event.files?.length > 0) {
		const reader = new FileReader();
		reader.addEventListener("load", () => {
			coverImagePreviewUrl.value = reader.result as string;
		});
		reader.readAsDataURL(event.files[0]);
	} else {
		coverImagePreviewUrl.value = "";
	}
}

// Edit post

async function onClickEditPost() {
	// slug check
	slug.value = await store.createSlug(form.title);
	// const slugBool = await store.slugCheck(slug.value);

	// if (!slugBool) {
	// 	toast.openToastError($t("app.admin.errorMsg.titleExists"));
	// 	return false;
	// }

	if (form.content.length > 4_000_000_000) {
		toast.openToastError($t("app.admin.errorMsg.tooLongContent"));
		return false;
	}

	const formData = new FormData();
	for (const key in form) {
		if (key !== "image" && key !== "date" && key !== "livestream") {
			formData.append(key, (form as Record<string, any>)[key]);
		}
	}
	if (form.image) {
		formData.append("image", form.image);
	}

	if (form.date) {
		formData.append("date", store.formatDatepickerDate(form.date));
	}

	if (form.livestream && form.livestream !== "null") {
		formData.append("livestream", form.livestream);
	}

	if (slug.value) {
		formData.append("slug", slug.value);
	} else {
		toast.openToastError($t("app.errorMessage"));
		return false;
	}

	for (const entry of formData.entries()) {
		console.log(entry);
	}

	try {
		const res = await api.articleEdit(formData);
		toast.openToastSuccess($t("app.admin.editArticleSuccess"));
		// resetForm();
		console.warn(res.data);
	} catch (err: any) {
		toast.openToastError($t("app.errorMessage"));
		console.warn(err.message);
	}
}

// function resetForm() {
// 	form.date = "";
// 	form.title = "";
// 	form.image = null;
// 	form.image_alt = null;
// 	form.locale = "empty";
// 	form.status = "draft";
// 	form.content = "";
// 	form.livestream = null;
// 	form.description = "";
// }

watchEffect(() => {
	void getIdFromPath();
});

onUnmounted(() => {
	timeout && clearTimeout(timeout);
});
</script>

<template>
	<div class="admin-main-wrapper">
		<div class="main-title">{{ $t("app.admin.createArticleTitle") }}</div>
		<div
			v-if="articleItem"
			class="content-wrapper"
		>
			<div class="group">
				<label>
					{{ $t("app.admin.articleForm.title") }}*
					<span>&nbsp;({{ form.title?.length }}/255)</span>
				</label>
				<input
					v-model="form.title"
					type="text"
					placeholder="Title"
					maxlength="255"
				/>
			</div>
			<div class="group">
				<label>
					{{ $t("app.admin.articleForm.description") }}*
					<span>&nbsp;({{ form.description?.length }}/120)</span>
				</label>
				<input
					v-model="form.description"
					type="text"
					placeholder="Description"
					maxlength="120"
				/>
			</div>
			<div class="group">
				<label>{{ $t("app.admin.articleForm.date") }}*</label>
				<modern-date-picker
					v-model="form.date"
					:textInput="false"
					outputFormatString="DD.MM.YYYY HH:mm"
				></modern-date-picker>
			</div>
			<div class="group">
				<label>{{ $t("app.admin.articleForm.image") }}</label>
				<div class="admin-image-choose-wrapper">
					<file-picker
						title="Choose Cover Image"
						placeholder="Select an image file"
						:multiple="false"
						@change="handleCoverFileChange"
					>
						<template #buttonLabel>
							<span class="admin-file-picker-button-title">
								{{ $t("app.admin.chooseImage") }}
							</span>
						</template>
					</file-picker>
					<div class="admin-image-preview-wrapper">
						<img
							v-if="coverImagePreviewUrl"
							:src="coverImagePreviewUrl"
							alt="Preview Cover Image"
						/>
						<!-- <img
							v-else
							:src="store.noImagePath"
							alt="Preview Cover Image"
						/> -->
					</div>
				</div>
			</div>
			<!-- <div class="group">
				<label>
					{{ $t("app.admin.articleForm.imageAlt") }}
					<span>
						&nbsp;({{ form.image_alt ? form.image_alt.length : 0 }}/255)
					</span>
				</label>
				<input
					v-model="form.image_alt"
					type="text"
					maxlength="255"
					placeholder="Image alt"
				/>
			</div> -->
			<div class="group">
				<label>
					{{ $t("app.admin.articleForm.livestream") }}
					<span>
						&nbsp;({{ form.livestream ? form.livestream.length : 0 }}/255)
					</span>
				</label>
				<input
					v-model="form.livestream"
					type="text"
					maxlength="255"
					placeholder="Livestream link"
				/>
			</div>
			<div class="group">
				<label>{{ $t("app.admin.articleForm.locale") }}*</label>
				<select v-model="form.locale">
					<option
						value="empty"
						disabled
					>
						{{ $t("app.admin.articleForm.locale") }}
					</option>
					<option
						v-for="loc in store.validLocales"
						:key="loc"
						:value="loc"
					>
						{{ loc }}
					</option>
				</select>
			</div>
			<div class="group">
				<label>{{ $t("app.admin.articleForm.status") }}*</label>
				<select v-model="form.status">
					<option
						v-for="(value, index) in store.validStatuses"
						:key="index"
						:value="value"
						:selected="value === 'draft'"
					>
						{{ value }}
					</option>
				</select>
			</div>
			<div class="group">
				<label>{{ $t("app.admin.articleForm.content") }}*</label>
				<textarea
					v-model="form.content"
					cols="30"
					rows="10"
				></textarea>
			</div>
			<div class="admin-submit">
				<button
					:disabled="!isValidLoginForm"
					@click="onClickEditPost"
				>
					<span>{{ $t("app.submit") }}</span>
				</button>
				<!-- <div v-if="errorMsgBool">{{ errorMsgText }}</div> -->
			</div>
		</div>
		<div v-else>
			<span>Article not found!</span>
			<span>You will be redirected in 5 seconds</span>
		</div>
	</div>
</template>

<style lang="scss" scoped></style>
