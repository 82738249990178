import _definePage_default_0 from '/home/enon/Desktop/WBBL/wbbl project/src/pages/index/index.vue?definePage&vue'
import { _mergeRouteRecord } from 'unplugin-vue-router/runtime'
import _definePage_default_2 from '/home/enon/Desktop/WBBL/wbbl project/src/pages/index/about-us.vue?definePage&vue'
import _definePage_default_3 from '/home/enon/Desktop/WBBL/wbbl project/src/pages/index/contact.vue?definePage&vue'
import _definePage_default_4 from '/home/enon/Desktop/WBBL/wbbl project/src/pages/index/news.vue?definePage&vue'
import _definePage_default_5 from '/home/enon/Desktop/WBBL/wbbl project/src/pages/index/news.[id].[slug]/index.vue?definePage&vue'
import _definePage_default_6 from '/home/enon/Desktop/WBBL/wbbl project/src/pages/[...path].vue?definePage&vue'
import _definePage_default_7 from '/home/enon/Desktop/WBBL/wbbl project/src/pages/admin/create-article.vue?definePage&vue'
import _definePage_default_8 from '/home/enon/Desktop/WBBL/wbbl project/src/pages/admin/edit-article.[id]/index.vue?definePage&vue'
import _definePage_default_9 from '/home/enon/Desktop/WBBL/wbbl project/src/pages/admin/edit-articles.vue?definePage&vue'
import _definePage_default_10 from '/home/enon/Desktop/WBBL/wbbl project/src/pages/admin.vue?definePage&vue'
import _definePage_default_11 from '/home/enon/Desktop/WBBL/wbbl project/src/pages/auth/login.vue?definePage&vue'
import _definePage_default_12 from '/home/enon/Desktop/WBBL/wbbl project/src/pages/auth.vue?definePage&vue'

export const routes = [
  {
    path: '/',
    name: '/',
    component: () => import('/home/enon/Desktop/WBBL/wbbl project/src/pages/index.vue'),
    children: [
  _mergeRouteRecord(
      {
        path: '',
        name: 'HomePage',
        component: () => import('/home/enon/Desktop/WBBL/wbbl project/src/pages/index/index.vue'),
        /* no children */
      },
  _definePage_default_0
  ),
  _mergeRouteRecord(
      {
        path: 'about-us',
        name: 'AboutPage',
        component: () => import('/home/enon/Desktop/WBBL/wbbl project/src/pages/index/about-us.vue'),
        /* no children */
      },
  _definePage_default_2
  ),
  _mergeRouteRecord(
      {
        path: 'contact',
        name: 'ContactPage',
        component: () => import('/home/enon/Desktop/WBBL/wbbl project/src/pages/index/contact.vue'),
        /* no children */
      },
  _definePage_default_3
  ),
  _mergeRouteRecord(
      {
        path: 'news',
        name: 'NewsPage',
        component: () => import('/home/enon/Desktop/WBBL/wbbl project/src/pages/index/news.vue'),
        /* no children */
      },
  _definePage_default_4
  ),
      {
        path: 'news/:id/:slug',
        /* internal name: '//news.[id].[slug]' */
        /* no component */
        children: [
  _mergeRouteRecord(
          {
            path: '',
            name: 'ArticlePage',
            component: () => import('/home/enon/Desktop/WBBL/wbbl project/src/pages/index/news.[id].[slug]/index.vue'),
            /* no children */
          },
  _definePage_default_5
  )
        ],
      }
    ],
  },
  _mergeRouteRecord(
  {
    path: '/:path(.*)',
    name: 'CatchAllPage',
    component: () => import('/home/enon/Desktop/WBBL/wbbl project/src/pages/[...path].vue'),
    /* no children */
  },
  _definePage_default_6
  ),
  _mergeRouteRecord(
  {
    path: '/admin',
    name: 'AdminPage',
    component: () => import('/home/enon/Desktop/WBBL/wbbl project/src/pages/admin.vue'),
    children: [
  _mergeRouteRecord(
      {
        path: 'create-article',
        name: 'CreateArticle',
        component: () => import('/home/enon/Desktop/WBBL/wbbl project/src/pages/admin/create-article.vue'),
        /* no children */
      },
  _definePage_default_7
  ),
      {
        path: 'edit-article/:id',
        /* internal name: '/admin/edit-article.[id]' */
        /* no component */
        children: [
  _mergeRouteRecord(
          {
            path: '',
            name: 'EditArticle',
            component: () => import('/home/enon/Desktop/WBBL/wbbl project/src/pages/admin/edit-article.[id]/index.vue'),
            /* no children */
          },
  _definePage_default_8
  )
        ],
      },
  _mergeRouteRecord(
      {
        path: 'edit-articles',
        name: 'EditArticles',
        component: () => import('/home/enon/Desktop/WBBL/wbbl project/src/pages/admin/edit-articles.vue'),
        /* no children */
      },
  _definePage_default_9
  )
    ],
  },
  _definePage_default_10
  ),
  _mergeRouteRecord(
  {
    path: '/auth',
    name: 'AuthPage',
    component: () => import('/home/enon/Desktop/WBBL/wbbl project/src/pages/auth.vue'),
    children: [
  _mergeRouteRecord(
      {
        path: 'login',
        name: 'LoginPage',
        component: () => import('/home/enon/Desktop/WBBL/wbbl project/src/pages/auth/login.vue'),
        /* no children */
      },
  _definePage_default_11
  )
    ],
  },
  _definePage_default_12
  )
]
