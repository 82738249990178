<script setup lang="ts">
// import router from "@/router";
// import { useSessionStore } from "@/store/session";

definePage({
	name: "AdminPage",
	redirect: "/admin/create-article",
});

// const storeSession = useSessionStore();

// onMounted(async () => {
// 	if (!storeSession.isLoggedIn) {
// 		await router.push({
// 			path: "/",
// 		});
// 	}
// });
</script>

<template>
	<header-bar></header-bar>
	<div id="admin-page">
		<!-- <header-bar></header-bar> -->
		<router-view></router-view>
		<!-- <footer-bar></footer-bar> -->
	</div>

	<footer-bar></footer-bar>
</template>

<style lang="scss" scoped></style>
