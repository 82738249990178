import { defineStore } from "pinia";
import { useWindowSize } from "@vueuse/core";
import { useRouter, useRoute } from "vue-router";
import { simpleCrypt, simpleDecrypt } from "@/assets/js/helpers";
// import { DateInstance } from "@/assets/js/dateHelper";
import api from "@/api";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
// import { $t } from "@/i18n";
dayjs.extend(utc);
dayjs.extend(timezone);

// Format news date

function formatNewsDate(date: string, withTime: boolean): string {
	let res = dayjs.utc(date).local(); // temp solution !!!!!!!
	// console.log(res.format("YYYY-MM-DD HH:mm:ss"));

	return withTime
		? res.format("MMM DD, YYYY HH:mm")
		: res.format("MMM DD, YYYY");
}

// Format datepicker date

function formatDatepickerDate(date: string): string {
	// let res = dayjs(date).utc();
	// return res.format();
	const localDate = dayjs(date);
	const utcDate = localDate.utc();
	return utcDate.format("YYYY-MM-DD HH:mm:ss");
}

// Format datepicker date for output

function formatDatepickerDateForOutput(date: string): string {
	let res = dayjs(date);
	return res.format();
}

// Create slug

async function createSlug(s) {
	let slug = s.toLowerCase();
	slug = slug.replace(/đ/g, "dj");
	slug = slug.replace(/[čćžš]/g, function (match) {
		return {
			č: "c",
			ć: "c",
			ž: "z",
			š: "s",
		}[match];
	});
	slug = slug.replace(/[^a-z\d]+/g, "-");
	slug = slug.replace(/-+/g, "-");
	slug = slug.replace(/^-+|-+$/g, "");
	return slug;
}

// Check slug

// async function slugCheck(slug: string): Promise<boolean> {
// 	const params = {
// 		slug,
// 	};
// 	try {
// 		const res = await api.articlesGet(params);
// 		if (res.data.data.length > 0) return false;
// 		return true;
// 	} catch (err: any) {
// 		console.warn(err.message);
// 		return false;
// 	}
// }

// Remove article

async function removeArticle(id: number, sid: string) {
	const params = {
		art_id: id,
		sid: sid,
	};
	try {
		const res = await api.articleRemove(params);
		console.warn(res.data);
		return true;
	} catch (err: any) {
		console.warn(err.message);
		return false;
	}
}

export const useDefaultStore = defineStore("main", () => {
	const { width } = useWindowSize();
	const isMobileToggled = ref(false);
	const router = useRouter();
	const route = useRoute();

	const isMobileSize = computed(() => width.value < 768);
	const isMobileView = computed(
		() => isMobileSize.value && isMobileToggled.value,
	);

	// locale
	const activeLocale = ref("sr");

	watch(isMobileSize, (val) => {
		if (!val) {
			isMobileToggled.value = false;
		}
	});
	// const
	const noImagePath =
		"https://t4.ftcdn.net/jpg/04/70/29/97/360_F_470299797_UD0eoVMMSUbHCcNJCdv2t8B2g1GVqYgs.jpg";
	const validLocales = ["sr", "en"];
	const validStatuses = ["published", "draft"];
	const appName = "WBBL";
	const baseUrl = import.meta.env.VITE_APP_BASE_URL;
	const metaData = {
		// description:
		// 	"Discover WBBL: Empowering women's basketball across the Balkans through unity, excellence, and top-tier competition.",
		// keywords:
		// 	"WBBL basketball, Balkan women's basketball, Women's sports league, Basketball empowerment women, Basketball league Balkans, WBBL tournaments, Female athletes Balkans",
		robots: "index, follow",
		robotsNoIndex: "noindex, nofollow",
	};
	const modalConfirmRemoveBool = false;

	function decodePath(name: string) {
		return simpleDecrypt("abc", name);
	}
	function encodePath(name: string) {
		return simpleCrypt("abc", name);
	}
	async function onClickLoginPage() {
		// Used to return user to the previous page
		const currentPageName = String(route.name);

		// Exceptions - Auth pages
		let ppn: string | undefined = encodePath(currentPageName);
		if (route.matched.some((rt) => rt.name === "AuthPage")) {
			ppn = undefined;
		}

		await router.push({
			name: "LoginPage",
			query: {
				ppn,
			},
		});
	}

	async function goToProfilePage() {
		await router.push({
			// name: "SettingsPage",
			// name: "ProfilePage",
			name: "LoginPage",
		});
	}

	return {
		isMobileToggled,
		isMobileView,
		decodePath,
		encodePath,
		onClickLoginPage,
		goToProfilePage,
		formatNewsDate,
		activeLocale,
		noImagePath,
		validLocales,
		validStatuses,
		createSlug,
		// textRegex,
		// contentRegex,
		// slugCheck,
		formatDatepickerDate,
		// linkRegex,
		removeArticle,
		appName,
		metaData,
		baseUrl,
		// isPastDate,
		modalConfirmRemoveBool,
		// participants,
		formatDatepickerDateForOutput,
	};
});
