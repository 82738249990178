<script setup lang="ts">
import api from "@/api";
import { useDefaultStore } from "@/store";
import type { IArticleItem } from "@/components/landingPage/NewsSection.vue";
import { useRoute } from "vue-router";
import { $t } from "@/i18n";

definePage({
	name: "ArticlePage",
	meta: {
		name: "News Item",
	},
});

const route = useRoute();
const store = useDefaultStore();
const articleItem = ref<IArticleItem | null>(null);

// Get slug from url

async function getIdFromPath() {
	if (Array.isArray(route.params.id)) {
		const id = parseInt(route.params.id[0], 10);
		if (!isNaN(id) && id > 0) {
			await getNews(id);
			// console.log(id);
		}
	} else {
		const id = parseInt(route.params.id, 10);
		if (!isNaN(id) && id > 0) {
			await getNews(id);
			// console.log(id);
		}
	}
}

// Get article

async function getNews(id: number) {
	const params = {
		art_id: id,
		pastDate: true,
	};
	try {
		const res = await api.articlesGet(params);
		articleItem.value = res.data.data[0];
		console.log(articleItem.value);

		if (articleItem.value) {
			useHead({
				title: `${articleItem.value.art_title} | ${store.appName}`,
				meta: [
					{
						name: "title",
						content: `${articleItem.value.art_title} | ${store.appName}`,
					},
					{
						name: "description",
						content: articleItem.value.art_description,
					},
					{
						name: "keywords",
						content: `${$t("app.metaInfo.keywords")}`,
					},
					{ name: "robots", content: store.metaData.robots },
					// { name: "author", content: store.META_DATA.NAME_AUTHOR },
					{
						property: "og:title",
						content: `${articleItem.value.art_title} | ${store.appName}`,
					},
					{
						property: "og:description",
						content: articleItem.value.art_description,
					},
					{ property: "og:image", content: articleItem.value.img_image },
					{ property: "og:site_name", content: store.appName },
					{
						property: "og:url",
						content: store.baseUrl + route.path,
					},
					{ property: "og:type", content: "article" },
				],
			});
		}

		return true;
	} catch (err: any) {
		console.warn(err.message);
		return false;
	}
}

watchEffect(() => {
	void getIdFromPath();
});
</script>

<template>
	<div class="article-wrapper">
		<div
			v-if="articleItem"
			class="news-wrapper"
		>
			<router-link
				class="back-wrapper"
				:to="{ name: 'NewsPage' }"
			>
				<span class="back-arrow"></span>
				<span class="back-text">{{ $t("app.news.backToNews") }}</span>
			</router-link>
			<h2 class="title">{{ articleItem.art_title }}</h2>
			<div
				class="cover"
				:style="{ backgroundImage: `url(${articleItem.img_image})` }"
			></div>
			<p class="date">
				{{ store.formatNewsDate(articleItem.art_utc_date, true) }}
			</p>
			<!-- TEMP -->
			<pre
				class="content"
				v-html="articleItem.art_content"
			></pre>
			<div class="livestream-wrapper">
				<a
					v-if="articleItem.art_livestream"
					class="livestream"
					:href="articleItem.art_livestream"
					rel="nofollow"
					target="_blank"
				>
					{{ $t("app.news.watchLive") }}
				</a>
				<!-- <i-fa-circle-play></i-fa-circle-play> -->
			</div>
		</div>
		<div v-else>{{ $t("app.news.articleNotFound") }}</div>
	</div>
</template>

<style lang="scss" scoped>
.article-wrapper {
	margin-top: $nav-top-bar-sm;
	box-sizing: border-box;
	padding: 8px 0 24px;

	.news-wrapper {
		box-sizing: border-box;

		.back-wrapper {
			display: flex;
			.back-arrow {
				margin: 3px 8px 24px 24px;
				background-image: url("@/assets/image/back.svg");
				background-size: cover;
				height: 15px;
				aspect-ratio: 1 / 1;
			}

			.back-text {
				font-family: Roboto, sans-serif;
				font-size: 14px;
				font-weight: 500;
			}
		}

		.title {
			font-size: 26px;
			font-weight: bold;
			line-height: 28px;
			text-align: center;
			text-transform: uppercase;
			padding: 0 24px 24px;
		}

		.cover {
			width: 100%;
			aspect-ratio: 100 / 63;
			background-repeat: no-repeat;
			background-position: center;
			// background-size: cover;
			background-size: contain; // temp
		}

		.date {
			margin: 24px;
			color: rgba(13, 76, 145, 1);
		}

		.content {
			margin: 0 24px;
			font-size: 16px;
			font-family: Roboto, sans-serif;

			pre {
				margin: 0 24px;
				font-size: 16px;
				font-family: Roboto, sans-serif;
			}
		}

		.livestream-wrapper {
			margin: 24px;
			display: flex;
			align-items: center;
			.livestream {
				font-size: 16px;
				// color: rgb(0, 0, 238);
				color: $app-accent-color1;
				text-transform: uppercase;
				margin-right: 6px;
				&:hover {
					text-decoration: underline;
				}
			}
		}
	}
}

@media screen and (width >= 760px) {
	.article-wrapper {
		margin-top: $nav-top-bar;
		padding: 0 60px 24px;

		.news-wrapper {
			.back-wrapper {
				margin-top: 12px;
				.back-arrow {
					margin: 5px 8px 0 0;
					height: 21px;
				}

				.back-text {
					font-family: Roboto, sans-serif;
					font-size: 20px;

					&:hover {
						color: rgb(0, 0, 0);
					}
				}
			}
			.title {
				font-size: 28px;
				line-height: 32px;
				padding: 24px 0;
				margin: 0;
			}

			.date {
				margin: 24px 0;
				font-size: 16px;
			}

			.content {
				margin: 0;
				font-size: 18px;
				line-height: 24px;
			}

			.livestream-wrapper {
				margin: 24px 0;
				.livestream {
					font-size: 18px;
					line-height: 24px;
					// margin-right: 6px;
				}
			}
		}
	}
}

@media screen and (width >= 1224px) {
	// 1224px
	.article-wrapper {
		padding: 0 248px 100px;
		.news-wrapper {
			.title {
				font-size: 34px;
				line-height: 40px;
				padding: 68px 0 100px;
			}

			.date {
				margin: 24px 0;
				font-size: 16px;
				line-height: 22px;
			}

			.content {
				margin: 0;
				font-size: 20px;
				line-height: 26px;
			}

			.livestream-wrapper {
				margin: 24px 0;
				.livestream {
					font-size: 20px;
					line-height: 26px;
					// margin-right: 6px;
				}
			}
		}
	}
}

@media screen and (width >= 1024px) {
	.article-wrapper {
		.news-wrapper {
			.title {
				font-size: 40px;
				line-height: 44px;
			}
		}
	}
}
</style>
