import { useSessionStore } from "@/store/session";
import { createRouter, createWebHistory } from "vue-router/auto";

const router = createRouter({
	history: createWebHistory(),
});

router.beforeEach((to, from) => {
	// Forbid going to auth pages if user is logged in
	const authPage = "AuthPage";
	const adminPage = "AdminPage";
	if (to.matched.some((match) => match.name === authPage)) {
		const isLoggedIn = useSessionStore().isLoggedIn;
		if (isLoggedIn) {
			// return false;
			router.push({
				name: "HomePage",
			});
		}
	}
	if (to.matched.some((match) => match.name === adminPage)) {
		const isLoggedIn = useSessionStore().isLoggedIn;
		if (!isLoggedIn) {
			// return false;
			router.push({
				name: "HomePage",
			});
		}
	}
	return true;
});

export default router;
