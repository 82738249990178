<script setup lang="ts">
import { useDefaultStore } from "@/store";
import { $t } from "@/i18n";

definePage({
	name: "LoginPage",
});

const store = useDefaultStore();
const route = useRoute();

useHead({
	title: `${$t("app.auth.loginTitle")} | ${store.appName}`,
	meta: [
		{
			name: "title",
			content: `${$t("app.auth.loginTitle")} | ${store.appName}`,
		},
		{
			name: "description",
			content: `${$t("app.metaInfo.description")}`,
		},
		{
			name: "keywords",
			content: `${$t("app.metaInfo.keywords")}`,
		},
		{ name: "robots", content: store.metaData.robotsNoIndex },
		// { name: "author", content: store.META_DATA.NAME_AUTHOR },
		{
			property: "og:title",
			content: `${$t("app.auth.loginTitle")} | ${store.appName}`,
		},
		{
			property: "og:description",
			content: `${$t("app.metaInfo.description")}`,
		},
		// { property: "og:image", content: store.META_DATA.PROPERTY_IMAGE },
		{ property: "og:site_name", content: store.appName },
		{
			property: "og:url",
			content: store.baseUrl + route.path,
		},
		{ property: "og:type", content: "website" },
	],
});
</script>

<template>
	<div id="login-page">
		<!-- <div class="image-section">
			<div class="title">{{ $t("app.auth.loginImageTitle") }}</div>
			<div class="desc">{{ $t("app.auth.loginImageDesc") }}</div>
			<img
				alt="placeholder-image"
				src=""
			/>
		</div> -->
		<div class="comp-section">
			<login-comp></login-comp>
		</div>
	</div>
</template>

<style lang="scss">
// temp solution start
.full-button {
	border-radius: 0 !important;
}
.new-login-input {
	border-radius: 0 !important;
}
.auth-views {
	display: block !important;
	padding: 0 !important;
}
.auth {
	display: block !important;
}

#auth {
	.auth-views {
		max-width: 100% !important;
	}
}
.login {
	.modern-form {
		background-color: $background-color !important;
		border-radius: unset;
	}
	.title {
		span {
			display: block;
			text-align: center !important;
			font-size: 16px !important;
			// color: $text-color-lighter;
			// font-weight: lighter;
			font-family: Roboto, sans-serif !important;
			font-weight: 700 !important;
			// margin-bottom: 12px !important;
			margin: 24px 0;
		}
	}

	@media screen and (width > 768px) {
		.title {
			span {
				font-size: 28px !important;
				line-height: 32px !important;
			}
		}
	}

	@media screen and (width > 1024px) {
		.title {
			span {
				font-size: 34px !important;
				line-height: 40px !important;
			}
		}
	}

	@media screen and (width > 1572px) {
		.title {
			span {
				font-size: 30px !important;
				line-height: 35.16px !important;
			}
		}
	}
}
#login-page {
	margin-top: $nav-top-bar-sm !important;
	min-height: calc(100vh - $nav-top-bar-sm) !important;
	// display: flex;
	// flex-direction: column;
	// gap: 40px 170px;
	height: 100%;
	width: 100%;
	background-image: url("@/assets/image/login-cover.png");
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	display: flex !important;
	justify-content: center !important;
	align-items: center !important;

	.image-section,
	.comp-section {
		display: flex;
		align-items: center;
		justify-content: center;
		// margin-top: calc($nav-top-bar * 2);
	}

	.image-section {
		flex-direction: column;
		align-items: flex-start;
		gap: 20px;

		.title {
			font-family: Anton, sans-serif;
			font-size: clamp(1rem, 4vw, 60px);
			font-style: normal;
			font-weight: 400;
		}

		.desc {
			font-family: "Open Sans", sans-serif;
			font-size: clamp(0.8rem, 3.5vw, 24px);
			font-style: normal;
			font-weight: 400;
			line-height: normal;
		}

		img {
			width: 100%;
			max-width: 900px;
		}
	}

	@media screen and (width > 768px) {
		flex-direction: row;

		.image-section {
			flex-basis: 80%;
		}
		.comp-section {
			min-width: 400px;

			> * {
				width: 100%;
			}
		}
	}
}
</style>
